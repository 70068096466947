import './home.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { homeService } from "../../services/home";
import { Button, LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import { checkOffSeason } from '../../services/season';

type AccessibleLandmark = {
  name: string;
  _id: string;
  image_url: string;
  in_progress: boolean;
}

type Streak = {
  name: string;
  longest_streak: number;
  current_reward_streak: number;
  required_reward_streak: number;
  daily_completion: boolean;
}

export function Home() {
  const navigate = useNavigate();

  const navigateToBooking = () => {
    navigate('/booking');
  }

  const navigateToTradeHub = () => {
    navigate('/trade_hub');
  }

  const showTutorial = () => {
    localStorage.removeItem('rr-tutorial-watched');
    window.location.reload();
  }

  const [isLoading, setIsLoading] = useState(false);
  const [uniqueSouvenirs, setUniqueSouvenirs] = useState(0);
  const [duplicateSouvenirs, setDuplicateSouvenirs] = useState(0);
  const [vouchers, setVouchers] = useState(0);
  const [accessibleLandmarks, setAccessibleLandmarks] = useState<AccessibleLandmark[]>([]);
  const [maxSouvenirs, setMaxSouvenirs] = useState(0);
  const [streaks, setStreaks] = useState<Streak[]>([]);

  const getDetails = useCallback(() => {
    setIsLoading(true);
    homeService.getDetails().then((r) => {
      if (r && !r.error) {
        if(r && r.max_souvenirs) {
          setMaxSouvenirs(r.max_souvenirs);
        }
        if(r && r.unique_souvenirs) {
          setUniqueSouvenirs(r.unique_souvenirs);
        }
        if(r && r.duplicate_souvenirs) {
          setDuplicateSouvenirs(r.duplicate_souvenirs);
        }
        if(r && r.vouchers) {
          setVouchers(r.vouchers);
        }
        if(r && r.landmarks) {
          setAccessibleLandmarks(r.landmarks);
        }
        if(r && r.streaks) {
          setStreaks(r.streaks);
        }
        setIsLoading(false);
      } else {
        if (r.message && checkOffSeason(r.message.toString())){
          navigate(`/coming_soon`);
        }
        setIsLoading(false);
      }
      
    })
  }, [navigate])

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const normalise = (value: number) => ((value) * 100) / (30);

  const navigateToLandmark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  const navigateToFaq = () => {
    navigate(`/faq`);
  }

  const generateStreaksRow = (st: Streak) => {
    const elements = [];
    for(let i = 0; i < st.current_reward_streak; i++) {
      elements.push(<div className={'home-streaks-red-circle'}></div>);
    }
    for(let i = 0; i < st.required_reward_streak - st.current_reward_streak - 1; i++) {
      elements.push(<div className={'home-streaks-white-circle'}></div>)
    }
    if(st.current_reward_streak === st.required_reward_streak) {
      elements.push(<div className={'home-streaks-red-star'}></div>)
    } else {
      elements.push(<div className={'home-streaks-white-star'}> </div>)
    }
    return elements;
  }

  const generateStreakDailyCompletionImage = (st: Streak) => {
    const element = [];
    if (st.daily_completion) {
      element.push(<img src={'./green_checkmark.svg'} width={18} alt={''} />);    
    } else {
      element.push(<img src={'./red_x_mark.svg'} width={18} alt={''} />);
    }
    return element;
  }

  return (
    <div style={{minHeight: '100vh', paddingTop: '50px'}}>
      <AppHeader title={'Home'}/>

      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading && <div>
        <div>
          <div className={'home-header'}>Home</div>
          <div style={{padding: "0 12px"}}>
            <div style={{marginBottom: '12px', justifyContent: 'space-around', display: 'flex'}}>
              <Button onClick={() => showTutorial()} className={'home-btn'}>Tutorial</Button>
              <Button onClick={() => navigateToFaq()} className={'home-btn'}>FAQ</Button>
            </div>
            <div className={'home-row'}>
              <div className={'row-lbl'}>Unique Souvenirs {uniqueSouvenirs}/{maxSouvenirs}</div>
              <div>
                <LinearProgress sx={{
                  height: '12px',
                  borderRadius: '8px',
                  background: '#000',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#E81961'
                  }
                }}  variant="determinate" value={normalise(uniqueSouvenirs)} />
              </div>
            </div>
            <div className={'home-row'}>
              <div className={'row-lbl'}>Duplicate Souvenirs {duplicateSouvenirs}</div>
              <div>
                <Button onClick={() => navigateToTradeHub()} className={'home-btn'}>Go to Trading Hub</Button>
              </div>
            </div>
            <div className={'home-row'}>
              <div className={'row-lbl'}>Vouchers: {vouchers}</div>
              <Button onClick={() => navigateToBooking()} className={'home-btn'}>Book tickets</Button>
            </div>

            <div className={'home-landmarks-header'}>
              Streaks
            </div>

            <div className={'home-landmarks-subheader'} style={{marginBottom: "8px"}}>
              Perform certain tasks on consecutive days to earn bonus vouchers
            </div>

            <div className={'home-streaks-container'}>
              {streaks.map((st: Streak) =>
                <div className={'home-streaks-row'} key={st.name}>
                  <div>
                    <div className={'home-streaks-row-title'}>{st.name}</div>
                    <div className={'home-streaks-active-row'}>
                      {generateStreaksRow(st)}
                    </div>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div style={{marginLeft: '6px'}}>
                      <div className={'home-streaks-row-title'}>Daily</div>
                      {generateStreakDailyCompletionImage(st)}
                    </div>
                    <div style={{marginLeft: '6px'}}>
                      <div className={'home-streaks-row-title'}>Current</div>
                      <div className={'home-streaks-row-value'}>{st.current_reward_streak} {st.current_reward_streak === 1 ? 'day' : 'days'}</div>
                    </div>
                    <div style={{marginLeft: '6px'}}>
                      <div className={'home-streaks-row-title'}>Longest</div>
                      <div className={'home-streaks-row-value'}>{st.longest_streak} {st.longest_streak === 1 ? 'day' : 'days'}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className={'home-landmarks-header'}>
              Accessible locations
            </div>

            <div className={'home-landmarks-subheader'} style={{marginBottom: "12px"}}>
              These locations are playable by you right now because you own a green or blue Ticket for them. Tap to visit!
            </div>
            <div className={'home-landmarks-container'}>
              {accessibleLandmarks.length > 0 ?
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {accessibleLandmarks.map((l, index) =>
                    <div key={index} className={'inventory-souvenirs-item-container'}>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{maxHeight: '70px', maxWidth: '73px', position: 'relative'}}>
                          <div>
                            <img onClick={() => navigateToLandmark(l._id)} alt={l.name} style={{height: '70px', width: '73px', paddingLeft: '8px'}} src={l.image_url} />
                          </div>
                          {l.in_progress &&
                            <div style={{position: 'absolute', left: '-12px', top: '2px'}}>
                              <img src={'./pause_play.svg'} width={18} alt={''} />
                            </div>
                          }
                        </div>
                        <div className={'home-landmark-txt'}>{l.name}</div>
                      </div>
                    </div>
                  )}
                </div> :
                <div className={'home-landmarks-empty'}>
                  No accessible locations are open at the moment.
                </div>
              }
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}