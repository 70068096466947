import React, { useCallback, useEffect, useRef, useState } from 'react';
import './matchTwoGame.scss';
import Card from "./Card/Card";
import { matchTwoGameService } from "../../services/matchTwoGame";
import { Dialog, DialogContent, DialogProps } from "@mui/material";
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

function MatchTwoGame(props: { landmark_id: string, landmark_name: string }) {

  const [cards, setCards] = useState([]);
  const [availableMoves, setAvailableMoves] = React.useState(0);
  const [openCards, setOpenCards] = useState<number[]>([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [remainingMoves, setRemainingMoves] = useState(0);
  const timeout = useRef(null);
  const [cardsArray, setCardsArray] = useState([]);
  const [difficulty, setDifficulty] = useState('');
  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [backCard, setBackCard] = useState('');
  const [apiInProgress, setApiInProgress] = useState(false);

  const disable = () => {
    setShouldDisableAllCards(true);
  };

  const enable = () => {
    setShouldDisableAllCards(false);
  }

  const handleCardClick = (index: number) => {
    if(!apiInProgress) {
      setApiInProgress(true);
      matchTwoGameService.flipCard(index, props.landmark_id).then((r) => {
        if (r && !r.error) {
          let tmp = r;
          let tmpCards = [...cards];
          tmpCards[index] = cardsArray[tmp.image_array_idx];
          setCards(tmpCards);
          setRemainingMoves(r.remaining_moves);
          if (openCards.length === 1) {
            setOpenCards((prev) => [...prev, index]);
            disable();
            if (r && r.match) {

              //@ts-ignore
              timeout.current = setTimeout(() => {
                //@ts-ignore
                setClearedCards((prev) => ({...prev, [tmpCards[index].type]: true}));
                setOpenCards([]);
                if (r && r.status && r.status === 'failed') {
                  disable();
                } else {
                  enable();
                }
                setApiInProgress(false);
              }, 1000);
            } else {
              //@ts-ignore
              timeout.current = setTimeout(() => {
                setOpenCards([]);
                if (r && r.status && r.status === 'failed') {
                  disable();
                } else {
                  enable();
                }
                setApiInProgress(false);
              }, 1500);
            }
            if (r && r.status && r.status === 'failed') {
              setTimeout(() => {
                setOpenFailedDialog(true);
                setApiInProgress(false);
                return;
              }, 1000);
            }
            if (r && r.status && r.status === 'success') {
              setTimeout(() => {
                setOpenCongratulationsDialog(true);
                setApiInProgress(false);
                return;
              }, 1000);
            }
          } else {
            // @ts-ignore
            clearTimeout(timeout.current);
            setOpenCards([index]);
            setApiInProgress(false);
          }
        } else if (r && r.error) {
          setTimeout(() => {
            setOpenFailedDialog(true);
            setApiInProgress(false);
            return;
          }, 1000);
        }
      })
    }
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setupGame = useCallback(() => {
    sleep(1000).then(() => {
      matchTwoGameService.getGame(props.landmark_id).then((r) => {
        if (r && r.metadata && r.metadata.difficulty) {
          setDifficulty(r.metadata.difficulty);
        }
        if (r && r.metadata && r.metadata.available_moves) {
          setAvailableMoves(r.metadata.available_moves);
        }
        if (r && r.metadata && r.metadata.remaining_moves) {
          setRemainingMoves(r.metadata.remaining_moves);
        }
        if (r && r.metadata && r.metadata.cleared_cards) {
          setClearedCards(r.metadata.cleared_cards);
        }
        if (r && r.metadata && r.metadata.game_board && r.metadata.image_array) {
          let oc: any[] = [];
          let tmp = r.metadata.game_board.map((gb: any, i: number) => {
            if (gb !== 0 && !r.metadata.cleared_cards[(r.metadata.image_array[gb].type)]) {
              oc.push(i);
            }
            return r.metadata.image_array[gb];
          })
          setOpenCards(oc);
          setCards(tmp);
          setCardsArray(r.metadata.image_array);
          setBackCard(r.metadata.image_array[0].image);
        }
      })
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const checkIsFlipped = (index: any) => {
    // @ts-ignore

    return openCards.includes(index);
  };

  const checkIsInactive = (card: any) => {
    // @ts-ignore

    return Boolean(clearedCards[card.type]);
  };

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  return (
    <div style={{minHeight: '100vh', background: '#F8BD5D'}}>
      <div style={{paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'match-two-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'match-two-game-subheader'}>Concentration game</div>
        <div className={'match-two-game-description'}>
          Match pairs to win the {props.landmark_name} Souvenir! You have limited tries to succeed but if you fail your
          next attempt will be easier.
        </div>
        <div className="match-two-game-container">
          {cards.map((card: any, index: any) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={checkIsFlipped(index)}
                onClick={handleCardClick}
                backCard={backCard}
              />
            );
          })}
        </div>
      </div>
      <div className={'match-two-game-stats'}>
        <div>
          <div className={'match-two-game-lbl'}>Difficulty</div>
          <div className={'match-two-game-val'}>{difficulty}</div>
        </div>
        <div>
          <div className={'match-two-game-lbl'}>Number of tries</div>
          <div className={'match-two-game-val'}>{availableMoves}</div>
        </div>
        <div>
          <div className={'match-two-game-lbl'}>Remaining tries</div>
          <div className={'match-two-game-val'}>{remainingMoves}</div>
        </div>
      </div>


      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog landmark_name={props.landmark_name}
                                        closeDialog={handleCloseCongratulationsDialog}/>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MatchTwoGame