import './map.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { cityService } from "../../services/city";
import Loading from "../../components/Loading/Loading";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Snackbar } from "@mui/material";
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { checkOffSeason } from '../../services/season';

type City = {
  _id: string;
  name: string;
  country_id: string;
}

type Cities = City[]

type Landmark = {
  _id: string;
  name: string;
  owned: number;
  spares: number;
  image_url: string;
}

type SelectedCity = City & {
  description: string;
  landmarks: Landmark[];
}


export function Map() {

  const [isSelected, setIsSelected] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState<Cities>([]);
  const [selectedCity, setSelectedCity] = useState<SelectedCity>({
    _id: '',
    name: '',
    country_id: '',
    description: '',
    landmarks: []
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const onButtonClick = (city: string) => {
    const foundCity = cities.find((c) => {
      return c.name.toLowerCase() === city.toLowerCase();
    });
    if(foundCity) {
      cityService.getCity(foundCity._id).then((res: SelectedCity) => {
        if(res) {
          setSelectedCity(res);
          setIsSelected(true);
          setSearchParams({city: res._id});
        }
      })
    }
  }

  const navigateToLandMark = (id: string) => {
    navigate(`/landmark?id=${id}`);
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getCities = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      cityService.getCities().then((r: {cities: Cities, error?: boolean, message: string}) => {
        if(r.cities) {
          setCities(r.cities);
          const city = searchParams.get('city');
          if(city !== null) {
            const foundCity = r.cities.find((c) => {
              return c._id === city;
            });
            if(foundCity) {
              cityService.getCity(foundCity._id).then((res: SelectedCity) => {
                if(res) {
                  setSelectedCity(res);
                  setIsSelected(true);
                  setIsLoading(false);
                } else {
                  setIsLoading(false);
                }
              })
            }
          } else {
            setIsLoading(false);
          }
        }

        if(r.error) {
          if (r.message && checkOffSeason(r.message.toString())){
            navigate(`/rest_area`);
          }
          setPopupMessage(r.message);
          setShowPopup(true);
          setIsLoading(false);
        }
      })
    })
  }, [searchParams, navigate])

  useEffect(() => {
    getCities();
  }, [getCities]);

  const resetMap = () => {
    setIsSelected(false);
    setSelectedCity({
      _id: '',
      name: '',
      country_id: '',
      description: '',
      landmarks: []
    });
    searchParams.delete('city');
    setSearchParams(searchParams);
  }

  const navigateToTradeHub = () => {
    navigate(`/trade_hub`);
  }

  const navigateToBooking = () => {
    navigate(`/booking`);
  }

  return (
    <div className={'map-bg'}>
      <AppHeader title={'Map'}/>
      {isLoading && <Loading  color={''}/>}
      {!isLoading &&
        <div className={'map-inner-bg'} style={{background: "", height: '100vh', padding: '16px'}}>
          <div>
            <div className={'map-breadcrumb'} style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{display: 'flex'}}>
                <div style={{paddingTop: '7px', paddingLeft: '6px', display: 'flex', marginRight: '16px'}}>
                  <div>
                    <img style={{marginRight: '8px'}} alt={'map pin'} src={'./map_pin.svg'} width={17} height={21} />
                  </div>
                  <div onClick={() => resetMap()} className={'map-breadcrumb-txt'}>UK</div>
                </div>
                {isSelected && <div style={{paddingTop: '7px', display: 'flex'}}>
                  <div>
                    <img style={{marginRight: '8px'}} src={'./city_icon.svg'} alt={'city icon'} width={17} height={21} />
                  </div>
                  <div className={'map-breadcrumb-txt-city'}>{selectedCity.name}</div>
                </div>}
              </div>
              <div>
                {/*Right*/}
                <div style={{paddingTop: '3px'}}>
                  <img style={{marginRight: '8px'}} alt={'breadcrumb icon'} src={'./breadcrumb_icon.svg'} width={29} height={29} />
                </div>
              </div>
            </div>
            <div className={'map-icons-container'}>
              <div className={'map-icon'} style={{marginRight: '12px'}} onClick={() => navigateToTradeHub()}>
                <LoopOutlinedIcon style={{color: '#F9FED5'}} height={'30px'} width={'30px'}  />
              </div>
              <div className={'map-icon'} onClick={() => navigateToBooking()}>
                <LocalActivityIcon style={{color: '#F9FED5'}} height={'24px'} width={'24px'}  />
              </div>
            </div>


            {!isSelected ?
              <div style={{display:'flex', justifyContent: 'center', width: '100%', marginBottom: '12px'}}>

                <div style={{position: 'relative'}}>
                  <img src={'./uk_map.svg'} alt={'UK'}/>
                  <div onClick={() => onButtonClick('belfast')} style={{top: "230px", left: '80px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Belfast</div>
                  </div>
                  <div onClick={() => onButtonClick('birmingham')} style={{top: "375px", right: '50px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Birmingham</div>
                  </div>
                  <div onClick={() => onButtonClick('durham')} style={{top: "235px", right: '55px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Durham</div>
                  </div>
                  <div onClick={() => onButtonClick('edinburgh')} style={{top: "185px", right: '85px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Edinburgh</div>
                  </div>
                  <div onClick={() => onButtonClick('inverness')} style={{top: "100px", right: '85px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Inverness</div>
                  </div>
                  <div onClick={() => onButtonClick('london')} style={{top: "430px", right: '30px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>London</div>
                  </div>
                  <div onClick={() => onButtonClick('manchester')} style={{top: "310px", right: '70px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Manchester</div>
                  </div>
                  <div onClick={() => onButtonClick('skye')} style={{top: "95px", left: '105px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>Skye
                    </div>
                  </div>
                  <div onClick={() => onButtonClick('wales')} style={{top: "380px", left: '120px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div>
                      <div className={'map-city-label'}>Wales</div>
                    </div>
                  </div>
                  
                  <div onClick={() => onButtonClick('york')} style={{top: "290px", right: '50px', position: 'absolute'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img src={'./city_icon.svg'} alt={'city icon'} width={25} height={32} />
                    </div>
                    <div className={'map-city-label'}>York</div>
                  </div>
                </div>
              </div>
              :
              <div>

                {selectedCity.name.toLowerCase() === 'belfast' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./belfast_bg.svg'} alt={'Belfast Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'titanic belfast' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '30px', right: '100px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} height={46} src={l.image_url} alt={'Titanic Belfast'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "queen's university belfast" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', left: '3px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={85} src={l.image_url} alt={"Queen's University Belfast"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'aslan at cs lewis square' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '145px', right: '35px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'Aslan at CS Lewis Square'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


                {selectedCity.name.toLowerCase() === 'birmingham' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./birmingham_bg.svg'} alt={'Birmingham Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'aston hall' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '30px', right: '120px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65}src={l.image_url} alt={'Aston Hall'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'chamberlain clock' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '220px', left: '45px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} height={70} src={l.image_url} alt={'Chamberlain Clock'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'queen victoria' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', right: '70px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={50} src={l.image_url} alt={'Queen Victoria'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'durham' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./durham_bg.svg'} alt={'Durham Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'finchale priory' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '15px', right: '100px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Finchale Priory'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'crook hall gardens' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '155px', left: '55px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Crook Hall Gardens'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'vessels of life sculpture' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '280px', left: '80px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={40} src={l.image_url} alt={'Vessels of Life Sculpture'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'edinburgh' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./edinburgh_bg.svg'} alt={'Edinburgh Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "st giles' cathedral" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', right: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} height={60} src={l.image_url} alt={"St Giles' Cathedral"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'scott monument' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '15px', right: '55px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={30} src={l.image_url} alt={'Scott Monument'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "edinburgh's castle" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '325px', left: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"Edinburgh's Castle"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'inverness' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={400} src={'./inverness_bg.svg'} alt={'Inverness Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'the loch ness centre' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '335px', left: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={60} src={l.image_url} alt={'The Loch Ness Centre'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'inverness castle' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '205px', left: '140px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={60} src={l.image_url} alt={'Inverness Castle'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "the highlanders' museum" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '70px', right: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={90} height={40} src={l.image_url} alt={"The Highlanders' Museum"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'london' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./london_bg.svg'} alt={'London Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === "king's cross station" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '25px', left: '50px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} src={l.image_url} alt={"King's Cross Station"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'buckingham palace' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',  top: '320px', left: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Buckingham Palace'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'london bridge' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '235px', right: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={80} src={l.image_url} alt={'London Bridge'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'manchester' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./manchester_bg.svg'} alt={'Manchester Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'etihad stadium' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute',top: '70px', right: '15px' }}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={55} src={l.image_url} alt={'Etihad Stadium'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'town hall' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '140px', right: '145px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Town Hall'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'united trinity statue' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '285px', left: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'United Trinity Statue'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'skye' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./skye_bg.svg'} alt={'Skye Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'fairy glen' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '130px', left: '45px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={55} src={l.image_url} alt={'Fairy Glen'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'the old man of storr' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '300px', right: '10px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'The Old Man of Storr'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'kilt rock and mealt falls' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '20px', right: '35px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={60} src={l.image_url} alt={'Kilt Rock and Mealt Falls'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'wales' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./wales_bg.svg'} alt={'Wales Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'tintern abbey' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '250px', right: '15px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={'Tintern Abbey'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'st davids cathedral' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '210px', left: '5px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={65} src={l.image_url} alt={'St Davids Cathedral'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "national library of wales" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '100px', left: '110px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={70} src={l.image_url} alt={"National Library of Wales"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }

                {selectedCity.name.toLowerCase() === 'york' &&
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                      <img width={350} src={'./york_bg.svg'} alt={'York Background'} />
                      {selectedCity.landmarks.map((l) =>
                        <div key={l._id}>
                          {l.name.toLowerCase() === 'york minster' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '35px', right: '80px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={50} src={l.image_url} alt={'York Minster'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === 'micklegate bar' &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '305px', left: '0px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={75} src={l.image_url} alt={'Micklegate Bar'}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                          {l.name.toLowerCase() === "clifford's tower" &&
                            <div onClick={() => navigateToLandMark(l._id)} style={{position: 'absolute', top: '265px', left: '150px'}}>
                              <div style={{display: 'flex', justifyContent: 'center', marginBottom: '4px'}}>
                                <img width={55} src={l.image_url} alt={"Clifford's Tower"}/>
                              </div>
                              <div className={'map-landmark-lbl'}>
                                {l.name}
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                }


              </div>
            }


          </div>
        </div>
      }
      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}