import AppHeader from '../AppHeader/AppHeader';
import './restArea.scss';

export function RestArea(){

  return (
    <div>
      <div style={{ marginLeft: '-10px'}}>
        <AppHeader title={'Rest Area'}/>
      </div>
       

      <div className={'rest-area-container'}>
        <div className={'rest-area-container-header'}>
          <div style={{width: '300px', marginTop: '50px'}}><strong>Take a Break</strong></div>
        </div>
        <div className={'rest-area-img-container'}>
          <img width={200} height={200} src={'./rest_area.svg'} alt={'Rest Area'}/>
        </div>
        <div className={'rest-area-header'}>
          This page is only available during a tour
        </div>
        <div className={'rest-area-txt'}>
          Once the next tour starts you will be able to access this page. 
          In the meantime, take a well deserved break and rest up before the next tour starts. 
          You can keep in touch with all your travel companions between tours on our official Discord.
        </div>

        <a style={{textDecoration: 'none'}} href={'https://discord.com/app/invite-with-guild-onboarding/Rkz7vJpQc2'}>
          <div className='rest-area-container-discord'>
            <div>
              <img style={{marginRight: '12px', marginTop: '10px'}} width={25} src={'./discord-mark-white.svg'} alt={'discord logo'} />
            </div>
            <div>
              Discord
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default RestArea

