import AppHeader from '../AppHeader/AppHeader';
import './comingSoon.scss';

export function ComingSoon(){

  return (
    <div style={{minHeight: '100vh', paddingTop: '50px'}}>
      <div style={{ marginLeft: '-10px'}}>
        <AppHeader title={'Coming Soon'}/>
      </div>
       
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{maxWidth: '330px'}}>
          <div className={'coming-soon-header'}>Coming soon - Brazil</div>
          <div className={'coming-soon-subheader'}>Tour 7</div>
          <div style={{marginBottom: '20px'}}><img src={'./rr_general.png'} alt={'General RewardRush'} width={330} style={{borderRadius: '8px'}} /></div>
          <div className={'coming-soon-txt-header'}>
            We are excited to announce the next Reward Rush Tour, the Brazil, coming soon!
          </div>
          <div className={'coming-soon-txt'} style={{marginBottom: '12px'}}>
            Reward Rush is a periodic game we have created to give our best users a chance at our biggest rewards.
            Every month or so, we will release a new “tour” for you to play, with a travel focused theme.
          </div>
          <div className={'coming-soon-txt-sub'} style={{marginBottom: '24px'}}>
            You are already signed up for the upcoming tour when it launches!
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon