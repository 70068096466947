import './createSmartTrade.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import Loading from "../../components/Loading/Loading";
import { tradeService } from "../../services/trade";
import {
  Button,
  Checkbox, Dialog, DialogContent, DialogProps,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from "@mui/icons-material/Close";
import { checkOffSeason } from '../../services/season';


type Souvenir = {
  _id: string;
  name: string;
  count: number;
  city_name: string;
}

// type AvailableUser = {
//   name: string;
//   value: string;
// }

// type TradeSetupResponse = {
//   available_users: AvailableUser[];
//   souvenirs: Souvenir[];
//   user_spares: Souvenir[];
// }
export function CreateSmartTrade() {
  const [isLoading, setisLoading] = useState(false);
  const users = [{value: 'any', name: 'Any'}];
  const selectedUser = {value: 'any', name: 'Any'};
  const [availableSpares, setAvailableSpares] = useState<Souvenir[]>([]);
  const [selectedSpares, setSelectedSpares] = useState<Souvenir[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const availableSouvenirs = [{_id: 'any', name: 'Any'}];
  const selectedSouvenir = {_id: 'any', name: 'Any'};
  const navigate = useNavigate();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarTxt, setSnackBarTxt] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setupTrade = useCallback(() => {
    setisLoading(true);
    sleep(1000).then(() => {
      tradeService.tradeSetup({'type': 'smart'}).then((r) => {
        if (r && !r.error) {
          setAvailableSpares(r.user_spares);
          setisLoading(false);
          setSelectedSpares([]);
        } else {
          if (r.data && r.data.message && checkOffSeason(r.data.message.toString())){
            navigate(`/rest_area`);
          }
          setisLoading(false);
        }
      })
    })
  }, [navigate])

  useEffect(() => {
    setupTrade();
  }, [setupTrade]);

  const handleSouvenirChange = (event: SelectChangeEvent<any>) => {
    if(event.target.value.includes('select_all')) {
      if(selectAll) {
        setSelectAll(false);
        setSelectedSpares([]);
      } else {
        setSelectAll(true);
        setSelectedSpares(availableSpares);
      }
    } else {
      setSelectAll(false);
      setSelectedSpares(event.target.value);
    }
  };

  const navigateToTradeHub = () => {
    navigate('/trade_hub');
  }

  const createTrade = () => {
    const trade = {
      type: "smart",
      direct_user_id: 'any',
      requesting_souvenirs: [],
      trading_souvenirs: selectedSpares.map((spare) => {
        return spare._id;
      })
    };
    tradeService.createTrade(trade).then((r) => {
      if(r && r.message) {
        setSnackBarTxt(r.message);
        setSnackBarOpen(true);
        setupTrade();
      }
    })
  }

  const openHelpDialog = () => {
    setDialogOpen(true);
  }

  const closeHelpDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setDialogOpen(false);
  }

  return (
    <div style={{minHeight: '100vh', background: '#FBF8EE', paddingTop: '50px'}}>
      <AppHeader title={'Create Trade'}/>
      <div style={{padding: '12px'}}>
        {isLoading && <Loading color={'#E81961'} />}
        {!isLoading &&
          <div>
            <div className={'create-smart-trade-container-header'}>
              <div style={{paddingTop: '1px'}}><ArrowBackIosIcon onClick={() => navigateToTradeHub()} style={{color: '#5F607B'}} /></div>
              <div>New Smart Trade</div>
              <div style={{paddingTop: '1px'}}><QuestionMarkIcon onClick={() => openHelpDialog()} style={{background: '#63CAF3', color: '#FFF', borderRadius: '12px', fontSize: '18px'}} /></div>
            </div>
            <div className="create-smart-trade-description" style={{marginBottom: '24px'}}>
              Find what you want while helping someone out!
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-smart-trade-form-lbl'>
                Player to Trade with
              </div>
              <FormControl fullWidth>
                <Select
                  disabled
                  className={'create-smart-trade-li'}
                  value={selectedUser.value}
                >
                  {users.map((u: {value: string, name: string}, index) =>
                    <MenuItem  key={index} value={u.value}>
                      {u.name}
                  </MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-smart-trade-form-lbl'>
                Souvenir to Give *
              </div>
              <FormControl fullWidth>
                <Select
                  multiple
                  className={'create-smart-trade-li'}
                  value={selectedSpares}
                  onChange={handleSouvenirChange}
                  renderValue={(selected) => selected.map((s) => s.name).join(', ')}
                >
                  <MenuItem style={{height: '38px', minHeight: '38px'}} sx={{
                    "& .MuiTypography-root": {
                      color: '#000',
                      fontFamily: 'AntipastoRegular',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                    }
                  }} key={'select_all'} value={'select_all'}>
                    <Checkbox checked={selectAll} />
                    <ListItemText   primary={"Select all"} />
                  </MenuItem>
                  {availableSpares.map((spare: any) => (
                    <MenuItem style={{height: '38px', minHeight: '38px'}} sx={{
                      "& .MuiTypography-root": {
                        color: '#000',
                        fontFamily: 'AntipastoRegular',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      }
                    }} key={spare._id} value={spare}>
                      <Checkbox checked={selectedSpares.indexOf(spare) > -1} />
                      <ListItemText primary={spare.city_name + ': ' + spare.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{marginBottom: '12px'}}>
              <div className='create-smart-trade-form-lbl'>
                Souvenir to Get
              </div>
              <FormControl fullWidth>
                <Select
                  disabled
                  className={'create-smart-trade-li'}
                  value={selectedSouvenir._id}
                >
                  {availableSouvenirs.map((souvenir: any, index) =>
                    <MenuItem key={souvenir._id} value={souvenir._id}>
                      {souvenir._id === 'any' && <span>{souvenir.name}</span> }
                      {souvenir._id !== 'any' && <span>{souvenir.city_name}: {souvenir.name}</span> }
                    </MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className={'create-smart-trade-btn'}>
                <Button style={{width: '260px'}}
                  onClick={() => createTrade()}
                  disabled={selectedSpares.length === 0}
                  className={(selectedSpares.length === 0) ? 'create-trade-btn-dsbl' : 'create-trade-btn'}
                > Confirm
                </Button>
            </div>
        </div>}
      </div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarTxt}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={closeHelpDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: "14px",
              background: "#FFF"
            },
          },
        }}
      >
        <DialogContent>
          <div>
            <div  onClick={() => closeHelpDialog({}, 'escapeKeyDown')} style={{color: "#CCCCCC", position: 'absolute', right: '4px', top: '4px', display: 'inline-block'}}>
              <CloseIcon width={'28px'} height={'28px'} />
            </div>
            <div className={'smart-trade-help-title'}>What is a Smart Trade?</div>
            <div className={'smart-trade-help-txt'} style={{marginTop: '16px', marginBottom: '12px'}}>
              If you prefer NOT to interact with the community, you can set up your excess souvenirs to be traded automatically.
              Here, you can set your parameters, let the game know what you want and who you want to trade with,
              and the Smart Trade system will handle it from there. Be careful though, once you set this up,
              the system will start facilitating trades. If you want to hang onto a specific souvenir you have duplicates of,
              make sure to change your requirements. Once a trade has been completed, no refunds!
            </div>
            <div className={'smart-trade-help-txt'}>
              Fire and forget!
            </div>
          </div>
        </DialogContent>
      </Dialog>

    </div>
  )
}