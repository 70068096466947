import React, { useCallback, useEffect, useRef, useState } from 'react';
import './matchAndGoGame.scss';
import Card from "./Card/Card";
import { Button, Dialog, DialogContent, DialogProps } from "@mui/material";
import { matchAndGoGameService } from "../../services/matchAndGoGame";
import FailedDialog from "../FailedDialog/FailedDialog";
import CongratulationsDialog from "../CongratulationsDialog/CongratulationsDialog";

function MatchAndGoGame(props: { landmark_id: string, landmark_name: string }) {

  const [cards, setCards] = useState([]);
  const [availableMoves, setAvailableMoves] = React.useState(0);
  const [openCards, setOpenCards] = useState<number[]>([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [remainingMoves, setRemainingMoves] = useState(0);
  const timeout = useRef(null);
  const [cardsArray, setCardsArray] = useState([]);
  const [difficulty, setDifficulty] = useState('');
  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [openFailedDialog, setOpenFailedDialog] = useState(false);
  const [rewardsGained, setRewardsGained] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [backCard, setBackCard] = useState('');
  const [apiInProgress, setApiInProgress] = useState(false);


  const disable = () => {
    setShouldDisableAllCards(true);
    setRewardsGained([]);
  };

  const enable = () => {
    setShouldDisableAllCards(false);
  }

  const handleCardClick = (index: number) => {
    if(!apiInProgress) {
      setApiInProgress(true);
      matchAndGoGameService.flipCard(index, props.landmark_id).then((r) => {
        if (r && !r.error) {
          let tmp = r;
          let tmpCards = [...cards];
          tmpCards[index] = cardsArray[tmp.image_array_idx];
          setCards(tmpCards);
          setRemainingMoves(r.remaining_moves);
          if (openCards.length === 1) {
            setOpenCards((prev) => [...prev, index]);
            disable();
            if (r && r.match) {
              //@ts-ignore
              timeout.current = setTimeout(() => {
                //@ts-ignore
                setClearedCards((prev) => ({...prev, [tmpCards[index].type]: true}));
                setOpenCards([]);
                if (r && r.status && r.status === 'failed') {
                  setBtnDisabled(true);
                  disable();
                } else {
                  setBtnDisabled(false);
                  enable();
                }
                setApiInProgress(false);
              }, 1000);
            } else {
              //@ts-ignore
              timeout.current = setTimeout(() => {
                setOpenCards([]);
                if (r && r.status && r.status === 'failed') {
                  setBtnDisabled(true);
                  disable();
                } else {
                  enable();
                }
                setApiInProgress(false);
              }, 1500);
            }
            if (r && r.status && r.status === 'failed') {
              setTimeout(() => {
                setOpenFailedDialog(true);
                setBtnDisabled(true);
                setApiInProgress(false);
                return;
              }, 1000);
            }
            if (r && r.status && r.status === 'success') {
              setTimeout(() => {
                if (r && r.rewards_gained) {
                  setRewardsGained(r.rewards_gained);
                }
                setOpenCongratulationsDialog(true);
                setBtnDisabled(true);
                setApiInProgress(false);
                return;
              }, 1000);
            }
          } else {
            // @ts-ignore
            clearTimeout(timeout.current);
            setOpenCards([index]);
            setApiInProgress(false);
          }
        } else if (r && r.error) {
          setTimeout(() => {
            setOpenFailedDialog(true);
            setBtnDisabled(true);
            setApiInProgress(false);
            return;
          }, 1000);
        }
      })
    }
  };

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const setupGame = useCallback(() => {
    sleep(1000).then(() => {
      matchAndGoGameService.getGame(props.landmark_id).then((r) => {
        if (r && r.metadata && r.metadata.difficulty) {
          setDifficulty(r.metadata.difficulty);
        }
        if (r && r.metadata && r.metadata.available_moves) {
          setAvailableMoves(r.metadata.available_moves);
        }
        if (r && r.metadata && r.metadata.remaining_moves) {
          setRemainingMoves(r.metadata.remaining_moves);
        }
        if (r && r.metadata && r.metadata.cleared_cards) {
          setClearedCards(r.metadata.cleared_cards);
        }
        if (r && r.metadata && r.metadata.game_board && r.metadata.image_array) {
          let oc: number[] = [];
          let tmp = r.metadata.game_board.map((gb: any, i: number) => {
            if (gb !== 0 && !r.metadata.cleared_cards[(r.metadata.image_array[gb].type)]) {
              oc.push(i);
            }
            return r.metadata.image_array[gb];
          })
          setOpenCards(oc);
          setCards(tmp);
          setCardsArray(r.metadata.image_array);
          setBackCard(r.metadata.image_array[0].image);
          if(Object.keys(r.metadata.cleared_cards).length > 0) {
            setBtnDisabled(false);
          }
        }
      })
    })
  }, [props.landmark_id]);

  useEffect(() => {
    setupGame();
  }, [setupGame]);

  const checkIsFlipped = (index: any) => {
    // @ts-ignore

    return openCards.includes(index);
  };

  const checkIsCleared = (card: any) => {
    return clearedCards.hasOwnProperty(card.type);
  }

  const checkIsInactive = (card: any) => {
    // @ts-ignore

    return Boolean(clearedCards[card.type]);
  };

  const endGame = () => {
    setBtnDisabled(true);
    matchAndGoGameService.endGame(props.landmark_id).then((r) => {
      disable();
      if (r && r.error) {
        setOpenFailedDialog(true);
      } else {
        if (r && r.rewards_gained) {
          setRewardsGained(r.rewards_gained);
        }
        setOpenCongratulationsDialog(true);
      }
    })
  };

  const handleCloseCongratulationsDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenCongratulationsDialog(false);
    window.location.reload();
  }

  const handleCloseFailedDialog: DialogProps["onClose"] = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenFailedDialog(false);
    window.location.reload();
  }

  return (
    <div style={{minHeight: '100vh', background: '#F8BD5D'}}>
      <div style={{paddingTop: '12px', paddingBottom: '12px'}}>
        <div className={'match-two-go-game-header'}>
          {props.landmark_name}
        </div>
        <div className={'match-two-go-game-subheader'}>Match & Go game</div>
        <div className={'match-two-go-game-description'}>
          Match pairs to collect bonus vouchers and souvenirs! Avoid the "Bomb" &#x1F4A3; cards - if you match two bomb cards,
          you automatically lose &#x1F62C; &#x1F635;. To save your items, press the "End and Keep Matches" button at any time.
        </div>
        <div className="match-two-go-game-container">
          {cards.map((card: any, index: any) => {
            return (
              <Card
                key={index}
                card={card}
                index={index}
                isDisabled={shouldDisableAllCards}
                isInactive={checkIsInactive(card)}
                isFlipped={(checkIsFlipped(index) || checkIsCleared(card))}
                onClick={handleCardClick}
                backCard={backCard}
              />
            );
          })}
        </div>
      </div>
      <div className={'match-two-go-game-stats'} style={{marginBottom: '16px'}}>
        <div>
          <div className={'match-two-go-game-lbl'}>Difficulty</div>
          <div className={'match-two-go-game-val'}>{difficulty}</div>
        </div>
        <div>
          <div className={'match-two-go-game-lbl'}>Number of tries</div>
          <div className={'match-two-go-game-val'}>{availableMoves}</div>
        </div>
        <div>
          <div className={'match-two-go-game-lbl'}>Remaining tries</div>
          <div className={'match-two-go-game-val'}>{remainingMoves}</div>
        </div>
      </div>
      <div className="restart" style={{display: 'flex', justifyContent: 'center', paddingBottom: '16px'}}>
        <Button
          onClick={endGame}
          disabled={btnDisabled}
          color="primary"
          variant="contained"
          className={(!btnDisabled) ? 'match-two-go-btn' : 'match-two-go-btn-dsbl'}
        >
          End and keep matches
        </Button>
      </div>

      <Dialog
        open={openCongratulationsDialog}
        onClose={handleCloseCongratulationsDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <CongratulationsDialog rewards_gained={rewardsGained} landmark_name={props.landmark_name}
                                        closeDialog={handleCloseCongratulationsDialog}/>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openFailedDialog}
        onClose={handleCloseFailedDialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              margin: '0 16px',
              background: '#FFF',
              color: '#000'
            },
          },
        }}
      >
        <DialogContent>
          <FailedDialog landmark_name={props.landmark_name} closeDialog={handleCloseFailedDialog}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MatchAndGoGame