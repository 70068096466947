import './tradehub.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { Accordion, AccordionDetails, AccordionSummary, Button, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { tradeService } from "../../services/trade";
import Loading from "../../components/Loading/Loading";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { checkOffSeason } from '../../services/season';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

type TradeHistoryItem = {
  _id: string;
  type: string;
  status: string;
  created_at: string;
  offering: {landmark: string, city: string}[];
  requesting: {landmark: string, city: string}[];
  player: string;
}

export function TradeHub() {
  const [isLoading, setIsLoading] = useState(false);
  const [trades, setTrades] = useState<TradeHistoryItem[]>([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');


  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const navigate = useNavigate();

  const getTrades = useCallback(() => {
    setIsLoading(true);
    sleep(1000).then(() => {
      tradeService.getTrades().then((r) => {
        if (r.error) {
          if (r.data && r.data.message && checkOffSeason(r.data.message.toString())){
            navigate(`/rest_area`);
          }
          setIsLoading(false);
        } else {
          if(r && r.user_trades) {
            setTrades(r.user_trades);
          }
          setIsLoading(false);
        }
      })
    });

  }, [navigate]);

  useEffect(() => {
    getTrades();
  }, [getTrades]);

  
  const navigateToCreateSmartTrade = () => {
    navigate('/create_smart_trade');
  }

  const navigateToCreateManualTrade = () => {
    navigate('/create_manual_trade');
  }

  const cancelTrade = (trade: TradeHistoryItem) => {
    setIsLoading(true);
    tradeService.cancelTrade(trade._id).then((r) => {
      if(r && r.message) {
        setSnackBarMessage(r.message);
        setSnackBarOpen(true);
        getTrades();
      }
    })
  }


  return (
    <div className={'tradehub-container'}>
      <AppHeader title={'Trade Hub'}/>
      {isLoading && <Loading color={'#E81961'} /> }
      {!isLoading &&
        <div style={{padding: '16px'}}>
          <div className={'tradehub-container-header'}>
            <div style={{width: '300px'}}>Trade Hub</div>
            {/*<div style={{paddingTop: '1px'}}><QuestionMarkIcon style={{background: '#63CAF3', color: '#FFF', borderRadius: '12px', fontSize: '18px'}} /></div>*/}
          </div>
          <div className='tradehub-description'>
            Find what you want while helping someone out!
          </div>
          <div  style={{marginBottom: '24px'}}>
            <div className={'tradehub-create-trade-container'}>
              <div className={'tradehub-create-trade-txt'}> New Smart Trade</div>
              <div className={'tradehub-create-trade-icon'} onClick={() => navigateToCreateSmartTrade()}><ArrowForwardIosIcon /></div>
            </div>
            <div className={'tradehub-create-trade-container'}>
              <div className={'tradehub-create-trade-txt'}> New Manual Trade</div>
              <div className={'tradehub-create-trade-icon'} onClick={() => navigateToCreateManualTrade()}><ArrowForwardIosIcon /></div>
            </div>
          </div>
          <div className='tradehub-trades-header'>
           Trades
          </div>
          <div className={'tradehub-accordion-headers-container'}>
            <div style={{width: '200px'}}  className={'tradehub-accordion-headers'}>Offer</div>
            <div className={'tradehub-accordion-headers'}>Type</div>
          </div>

          <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center'}}>
            {trades.map((trade) => <div key={trade._id} className={'tradehub-accordion-wrapper'}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{color: "#FFF", background: '#F1045C', borderRadius: '12px'}} />}
                >
                  <span style={{width: '200px'}} className={'tradehub-accordion-txt'}>{trade.offering.length > 1 ? <span>Multiple</span> : <span>{trade.offering[0].city} - {trade.offering[0].landmark}</span> }</span>
                  <span className={'tradehub-accordion-txt'}>{trade.type}</span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={'tradehub-accordion-details-txt'}>
                    Requesting:
                  </div>
                  {trade.requesting.map((r, index) => <div key={index} className={'tradehub-accordion-details-txt'}>
                    <div style={{paddingLeft: '16px'}} >{r.city} - {r.landmark}</div>
                  </div> )}
                  <div className={'tradehub-accordion-details-txt'}>Player: {trade.player}</div>
                  <div className={'tradehub-accordion-details-txt'}>
                    Offering:
                  </div>
                  {trade.offering.map((r, index) => <div key={index} className={'tradehub-accordion-details-txt'}>
                    <div style={{paddingLeft: '16px'}} >{r.city} - {r.landmark}</div>
                  </div> )}
                  <div style={{textTransform: "capitalize"}}   className={'tradehub-accordion-details-txt'}>Status: {trade.status}</div>
                  <div className={'tradehub-accordion-details-txt'}>Created: {trade.created_at}</div>
                  {trade.status.toLowerCase() === 'pending' &&
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '8px'}}>
                      <Button onClick={() => cancelTrade(trade)} className='tradehub-history-item-btn'>Cancel Trade</Button>
                    </div>
                  }
                </AccordionDetails>
              </Accordion>
            </div>)}
          </div>

        </div>
      }
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarMessage}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}